import { Box, FormHelperText, FormLabel } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/webpack-resolver";
import React, { Component } from "react";
import AceEditor from "react-ace";
import { InputHelperText, TextInputProps, useInput } from "react-admin";

const styles = {};

type JSONInputProps = {
  input: { value: string; onChange: (value: string) => void };
};

type JSONInputState = {
  value: string;
  editedValue: string;
};

class AceInput extends Component {
  props: JSONInputProps;
  state: JSONInputState;

  constructor(props: JSONInputProps) {
    super(props);
    this.state = {
      value: "",
      editedValue: "",
    };
    this.props = props;
  }

  componentDidMount() {
    const {
      input: { value },
    } = this.props;

    this.setState({ value, editedValue: JSON.stringify(value, null, "\t") });
  }

  handleValueChange(stringValue: string) {
    try {
      let value = JSON.parse(stringValue);
      this.setState({ value: value, editedValue: stringValue });
      this.props.input.onChange(value);
    } catch (e) {
      this.setState({ editedValue: stringValue });
      this.props.input.onChange(stringValue);
    }
  }

  render() {
    return (
      <AceEditor
        mode="json"
        theme="github"
        value={this.state.editedValue || ""}
        onChange={(data) => this.handleValueChange(data)}
        name="UNIQUE_ID_OF_DIV"
        editorProps={{ $blockScrolling: true, worker: false }}
      />
    );
  }
}

export const JSONEditor2 = (props: TextInputProps) => {
  const propsValidate = Array.isArray(props.validate)
    ? props.validate
    : [...(props.validate ? [props.validate] : [])];

  const validateJSON = (value: any) => {
    if (!value || typeof value === "object") {
      return undefined;
    }
    try {
      JSON.parse(value);
      return undefined;
    } catch (e) {
      if (e instanceof SyntaxError) {
        return e.message;
      }
      return "Invalid JSON passed";
    }
  };

  const validators = [...propsValidate, validateJSON];

  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput({
    ...props,
    validate: validators,
  });

  return (
    <FormControl fullWidth={true} className="ra-input-mde">
      <FormLabel required={isRequired} style={{ fontSize: 13 }}>
        {props.label}
      </FormLabel>
      <Box mt={1}>
        <AceInput input={{ value: rest.value, onChange: onChange }} />
      </Box>
      <FormHelperText error={error}>{error || <>&nbsp;</>}</FormHelperText>
    </FormControl>
  );
};
