// in src/App.js
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { getCategoriesFromCsv, OfficialCategoryRecord } from "../utils";
import { httpClient } from "../dataProvider";
import { API_URL, LABELS } from "../constants";
import { HttpError, LoadingIndicator, useRefresh } from "react-admin";
import { countries } from "countries-list";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const InfoText = () => (
  <>
    Drag a file here or click to upload. Column headers are:
    <ul>
      <li>
        <strong>ID</strong>: the ID of the template category. Required
      </li>
      <li>
        <strong>Name</strong>: the internal name of the template category.
        Required
      </li>
      <li>
        <strong>Display Name</strong>: the user-facing display name of the
        template category. Required
      </li>
      <li>
        <strong>Priority</strong>: Defaults to 0 if not provided
      </li>
      <li>
        <strong>Priority Consumer</strong>
      </li>
      <li>
        <strong>Priority Prosumer</strong>
      </li>
      <li>
        <strong>Priority Multiplayer</strong>
      </li>
      <li>
        <strong>Height Ratio Override</strong>: Defaults to 1 if not provided
      </li>
      <li>
        <strong>Draft</strong>: is the template category a draft? Default true
      </li>
      <li>
        <strong>Show on Home Page</strong>: should the template category be
        shown on the home page? Default false
      </li>
      <li>
        <strong>Country Allow List</strong>: the list of countries that are
        allowed to access the template category, separated by commas. E.g.
        "GB,FR,DE". Defaults to an empty list
      </li>
      <li>
        <strong>Country Exclude List</strong>: the list of countries that are
        excluded from accessing the template category, separated by commas. E.g.
        "GB,FR,DE". Defaults to an empty list
      </li>
      <li>
        <strong>Tags</strong>: the list of labels associated with the template
        category, separated by commas. E.g. "person,cat,dog". Defaults to an
        empty list
      </li>
    </ul>
  </>
);

async function uploadCategories(records: OfficialCategoryRecord[]) {
  const throwError = (record: OfficialCategoryRecord, cause?: Error) => {
    const recordIndex = records.indexOf(record);
    if (recordIndex === 0) {
      throw new Error(
        `Failed to upload record ${record.id}. No records have been imported. Please try again`,
        { cause }
      );
    } else {
      throw new Error(
        `Failed to upload record ${record.id}. (The ${recordIndex} categories up to this point have been imported.) Please try again`,
        { cause }
      );
    }
  };

  for (const record of records) {
    try {
      await httpClient(`${API_URL}/v1/template-category/`, {
        method: "POST",
        body: JSON.stringify(record),
      });
    } catch (err) {
      if (err instanceof HttpError) {
        if (err.status === 400) {
          if (
            err.body.error.details?.[0]?.field === "id" &&
            err.body.error.details?.[0]?.code === "unique"
          ) {
            // Patch instead of post
            try {
              await httpClient(
                `${API_URL}/v1/template-category/${record.id}/`,
                {
                  method: "PATCH",
                  body: JSON.stringify(record),
                }
              );
            } catch (err) {
              throwError(record, err instanceof Error ? err : undefined);
            }
          }
        }
      } else if (err instanceof Error) {
        throwError(record, err);
      } else {
        throwError(record);
      }
    }
  }
}

const ImportTemplateCategoryCSVModal = ({ isOpen, onClose }: Props) => {
  const [records, setRecords] = useState<OfficialCategoryRecord[] | undefined>(
    undefined
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [complete, setComplete] = useState(false);
  const refresh = useRefresh();

  const resetAndClose = () => {
    onClose();
    // avoid flicker by waiting until the modal has faded out
    setTimeout(() => {
      setRecords(undefined);
      setError(undefined);
      setComplete(false);
    }, 500);
  };

  const onSelectFile = async (file: File) => {
    try {
      const records = await getCategoriesFromCsv(
        file,
        LABELS,
        Object.keys(countries)
      );
      setRecords(records);
      await uploadCategories(records);
      setComplete(true);
      setError(undefined);
      refresh();
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  let modalContent: JSX.Element | null = null;
  if (error) {
    modalContent = (
      <>
        <Alert severity="error">{error}</Alert>
        <InfoText />
      </>
    );
  } else if (records !== undefined) {
    modalContent = (
      <Box display="flex" alignItems="center">
        {!complete ? (
          <>
            <Box marginRight={1}>
              <CircularProgress />
            </Box>
            <strong>Importing {records.length} items...</strong>
          </>
        ) : (
          <>
            <strong>{records.length} items imported successfully.</strong>
          </>
        )}
      </Box>
    );
  } else {
    modalContent = <InfoText />;
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Load from CSV</DialogTitle>
      <DialogContent
        onDrop={(e) => {
          if (
            e.dataTransfer.files !== null &&
            e.dataTransfer.files.length > 0
          ) {
            onSelectFile(e.dataTransfer.files[0]);
          }
        }}
      >
        {modalContent}
      </DialogContent>
      <DialogActions>
        {complete ? (
          <Button onClick={resetAndClose} color="primary">
            Close
          </Button>
        ) : (
          <>
            <Button onClick={resetAndClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" color="primary">
              <input
                type="file"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  cursor: "pointer",
                  zIndex: 1,
                }}
                accept=".csv,text/csv"
                onChange={(e) => {
                  if (e.target.files !== null && e.target.files.length > 0) {
                    onSelectFile(e.target.files[0]);
                  }
                }}
              />
              Select CSV...
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ImportTemplateCategoryCSVModal;
