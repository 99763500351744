import firebase from "@firebase/app";
import { firebaseApp } from "./lib/firebase";

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { DataProviderError } from "./dataProvider";

const auth = getAuth(firebaseApp);

export default {
  login: ({ token }: { token: string }) => {
    return Promise.resolve();
  },
  logout: () => {
    console.log("logout");
    auth.signOut();
    return Promise.resolve();
  },
  checkError: (error: DataProviderError) => {
    console.error(error);
    return Promise.resolve();
  },
  checkAuth: (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      auth.onAuthStateChanged(
        (user) => {
          if (user) {
            resolve();
          } else {
            reject();
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  getPermissions: () => {
    return Promise.resolve();
  },
};
