// in src/App.js
import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Admin,
  Resource,
  List,
  Create,
  Datagrid,
  Edit,
  TextInput,
  DateField,
  BooleanInput,
  SelectInput,
  SimpleForm,
  NumberField,
  ReferenceInput,
  Filter,
  NumberInput,
  TextField,
  SelectArrayInput,
  CloneButton,
  EditButton,
  BooleanField,
  EditProps,
  CreateProps,
  ListProps,
  Layout,
  AppBar,
  AppBarProps,
  LayoutProps,
  BulkDeleteButton,
  BulkActionProps,
  useDataProvider,
  Record,
  AutocompleteInput,
  useRefresh,
  ListContextProvider,
  useList,
  Identifier,
  DateTimeInput,
  required,
  SimpleFormProps,
  ToolbarProps,
  Toolbar,
  SaveButton,
  SelectField,
  AutocompleteArrayInput,
  FormDataConsumer,
  TopToolbar,
  CreateButton,
  ExportButton,
  useResourceContext,
} from "react-admin";
import { countries } from "countries-list";
import { JSONEditor2 as JSONEditor } from "./components/JSONField";
import { ImageField } from "./components/ImageField";
import { Brush, Category, Layers, Receipt } from "@material-ui/icons";
import drfProvider, { httpClient } from "./dataProvider";
import ImportOfficialTemplates from "./pages/import-official-templates";
import PreviewHomepage from "./pages/preview-homepage";
import { API_URL, LABELS } from "./constants";
import { LoginPage } from "./components/LoginPage";
import authProvider from "./authProvider";
import CountryList from "./components/CountryList";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField as MuiTextField,
  Paper,
  Container,
  Modal,
  Card,
} from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import UploadIcon from "@material-ui/icons/CloudUpload";
import HomeIcon from "@material-ui/icons/Home";
import { Route, useHistory } from "react-router-dom";
import { getCategoriesFromCsv } from "./utils";
import ImportTemplateCategoryCSVModal from "./components/ImportTemplateCategoriesCSVModal";

const COUPON_TYPES = [{ id: "pro", name: "Pro" }];
const COUPON_DURATION_CHOICES = [
  {
    id: 1,
    name: "1 day",
  },
  {
    id: 3,
    name: "3 days",
  },
  {
    id: 7,
    name: "1 week",
  },
  {
    id: 31,
    name: "1 month",
  },
  {
    id: 93,
    name: "3 months",
  },
];

const MUTATION_MODE = "pessimistic";

const resourceToKlass: Map<string, string> = new Map([
  ["v2/templates", "Template"],
  ["v1/template-category", "TemplateCategory"],
]);

const dataProvider = drfProvider(API_URL);

const TemplateEdit = (props: EditProps) => (
  <div>
    <Edit {...props} mutationMode={MUTATION_MODE}>
      <SimpleForm>
        <ImageField source="imagePath" width={240} />
        <TextInput source="id" />
        <TextInput source="name" fullWidth={true} />

        <ReferenceInput
          source="categoryId"
          perPage={500}
          reference="v1/template-category"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <NumberInput source="aspectRatio.width" />
        <NumberInput source="aspectRatio.height" />
        <JSONEditor source="concepts" label="concepts" />
        <BooleanInput source="replaceBackgroundOverride" />
        <BooleanInput source="keepImportedImageSize" />
        <BooleanInput source="filterOnly" />
        <BooleanInput source="isPro" />
        <BooleanInput source="private" />
        <NumberInput source="priority" />
        <NumberInput source="version" />
        <TextInput source="imagePath" fullWidth={true} />
      </SimpleForm>
    </Edit>
    <AuditEventList {...props} />
  </div>
);

const ImprovedCategoryFilter = (props: any) => {
  const dataProvider = useDataProvider();
  const [templateCategories, setTemplateCategories] = useState<Record[]>([]);

  useEffect(() => {
    let isActive = true;

    dataProvider
      .getList("v1/template-category", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then((response) => {
        if (isActive) {
          setTemplateCategories(response.data);
        }
      });
    return () => {
      isActive = false;
    };
  }, [dataProvider]);

  return (
    <Filter {...props}>
      <AutocompleteInput
        label="Category"
        source="categoryId"
        choices={templateCategories.map((category) => ({
          id: category.id,
          name: category.name,
        }))}
      />
    </Filter>
  );
};

const ChangeCategoryButton = (props: BulkActionProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const refresh = useRefresh();

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = (categoryId: string) => {
    const { selectedIds } = props;

    setIsOpen(false);

    if (selectedIds !== undefined) {
      for (const templateId of selectedIds) {
        httpClient(`${API_URL}/template/${templateId}/`, {
          method: "PATCH",
          body: JSON.stringify({
            categoryId: categoryId,
            updatedAt: new Date().toISOString(),
          }),
        }).then(() => {
          refresh();
        });
      }
    }
  };

  const dataProvider = useDataProvider();
  const [templateCategories, setTemplateCategories] = useState<Record[]>([]);

  useEffect(() => {
    let isActive = true;

    dataProvider
      .getList("v1/template-category", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then((response) => {
        if (isActive) {
          setTemplateCategories(response.data);
        }
      });
    return () => {
      isActive = false;
    };
  }, [dataProvider]);

  return (
    <>
      <Button onClick={handleClick}>Change category</Button>
      <Dialog open={isOpen} onClose={handleDialogClose}>
        <DialogTitle>Change templates category</DialogTitle>
        <SimpleForm
          save={({ categoryId }: { categoryId: string }) => {
            handleConfirm(categoryId);
          }}
          saving={false}
        >
          <AutocompleteInput
            source="categoryId"
            choices={templateCategories.map((category) => ({
              id: category.id,
              name: category.name,
            }))}
          />
        </SimpleForm>
      </Dialog>
    </>
  );
};

const PostBulkActionButtons = (props: BulkActionProps) => (
  <>
    <ChangeCategoryButton {...props} />
    {/* Add the default bulk delete action */}
    <BulkDeleteButton {...props} />
  </>
);

const AuditEventList = (props: EditProps) => {
  const dataProvider = useDataProvider();
  const [auditEvents, setAuditEvents] = useState<Record[]>([]);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let isActive = true;

    if (props.resource === undefined) {
      return;
    }

    dataProvider
      .getList("audit-event", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "history_date", order: "DESC" },
        filter: {
          klass: resourceToKlass.get(props.resource),
          obj_id: props.id,
        },
      })
      .then((response) => {
        if (isActive) {
          setAuditEvents(response.data);
          setLoading(false);
          setLoaded(true);
        }
      });
    return () => {
      isActive = false;
    };
  }, [props.id, props.resource, dataProvider]);

  const ids: Identifier[] = [];

  const listContext = useList({
    data: auditEvents,
    ids,
    loading,
    loaded,
  });

  return (
    <Paper>
      <Container>
        <h3>Latest changes</h3>
        <ListContextProvider value={listContext}>
          <Datagrid>
            <DateField
              source="history_date"
              showTime
              label="Modification time"
            />
            <TextField source="history_user" label="Changed by" />
            <TextField source="history_event_type" label="Change type" />
            <TextField source="diff_data" label="Changes" />
          </Datagrid>
        </ListContextProvider>
      </Container>
    </Paper>
  );
};

const TemplateList = (props: ListProps) => (
  <List
    {...props}
    perPage={100}
    sort={{ field: "updatedAt", order: "DESC" }}
    filters={<ImprovedCategoryFilter />}
    bulkActionButtons={<PostBulkActionButtons />}
  >
    <Datagrid rowClick="edit">
      <DateField source="updatedAt" />
      <TextField source="id" />
      <ImageField source="imagePath" />
      <TextField source="name" />
      <BooleanField source="filterOnly" />
      <NumberField source="priority" />
      <NumberField source="priorityConsumer" />
      <NumberField source="priorityProsumer" />
      <NumberField source="priorityMultiplayer" />
      <TextField source="categoryId" />
      <NumberField source="version" />
      <BooleanField source="isPro" />
      <EditButton />
    </Datagrid>
  </List>
);

const ImportTemplateCategoryCSVButton = () => {
  const resource = useResourceContext();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <ImportTemplateCategoryCSVModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <Button
        color="primary"
        onClick={() => setIsModalOpen(true)}
        startIcon={<UploadIcon />}
        size="small"
      >
        Load from CSV
      </Button>
    </>
  );

  return null;
};

const TemplateCategoryListActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <ExportButton />
      <ImportTemplateCategoryCSVButton />
    </TopToolbar>
  );
};

const HorizontalScrollCard = (props: any) => {
  // Hacky workaround that sets a fixed width on the card taking into account the drawer width,
  // and allows the card to scroll horizontally.
  return (
    <Card
      {...props}
      style={{ overflowX: "auto", width: "calc(100dvw - 280px)" }}
    >
      {props.children}
    </Card>
  );
};

// Template category
export const TemplateCategoryList = (props: ListProps) => (
  <List
    {...props}
    perPage={500}
    sort={{ field: "priority", order: "DESC" }}
    actions={<TemplateCategoryListActions />}
    component={HorizontalScrollCard}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="displayName" />
      <NumberField source="priority" />
      <NumberField source="priorityConsumer" />
      <NumberField source="priorityProsumer" />
      <NumberField source="priorityMultiplayer" />
      <NumberField source="heightRatioOverride" />
      <BooleanField source="draft" />
      <BooleanField source="showOnHomePage" />
      <CountryList source="countryExcludeList" />
      <CountryList source="countryAllowList" />
      <TextField source="tags" />
      <CloneButton />
      <EditButton />
    </Datagrid>
  </List>
);

const COUNTRY_CHOICES = Object.entries(countries)
  .map(([code, data]) => {
    return { id: code, name: `${data.name} ${data.emoji}` };
  })
  .sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

export const TemplateCategoryEdit = (props: EditProps) => {
  return (
    <div>
      <Edit {...props} mutationMode={MUTATION_MODE}>
        <SimpleForm>
          <CloneButton />
          <JSONEditor source="tags" label="Tags for search" />

          <TextInput source="id" />
          <TextInput source="name" />
          <TextInput source="displayName" />
          <NumberInput source="priority" />
          <NumberInput source="priorityConsumer" />
          <NumberInput source="priorityProsumer" />
          <NumberInput source="priorityMultiplayer" />
          <NumberInput source="heightRatioOverride" />
          <BooleanInput source="draft" />
          <BooleanInput source="showOnHomePage" />

          <SelectArrayInput
            label="RecommendedFor"
            source="recommendedFor"
            choices={LABELS.map((label) => {
              return { id: label, name: label };
            })}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const filteredCountryChoicesForAllow = COUNTRY_CHOICES.filter(
                (choice) => !formData.countryExcludeList?.includes(choice.id)
              );
              return (
                <>
                  <small className="helper-text">
                    Adding a country to allow list will remove it from the
                    exclude list choices.
                  </small>
                  <AutocompleteArrayInput
                    label="Country allow list"
                    source="countryAllowList"
                    choices={filteredCountryChoicesForAllow}
                    {...rest}
                  />
                </>
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const filteredCountryChoicesForExclude = COUNTRY_CHOICES.filter(
                (choice) => !formData.countryAllowList?.includes(choice.id)
              );
              return (
                <>
                  <small className="helper-text">
                    Adding a country to exclude list will remove it from the
                    allow list choices.
                  </small>
                  <AutocompleteArrayInput
                    label="Country exclude list"
                    source="countryExcludeList"
                    choices={filteredCountryChoicesForExclude}
                    {...rest}
                  />
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
      <AuditEventList {...props} />
    </div>
  );
};

export const TemplateCategoryCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
      <TextInput source="displayName" />
      <NumberInput source="priority" />
      <NumberInput source="priorityConsumer" />
      <NumberInput source="priorityProsumer" />
      <NumberInput source="priorityMultiplayer" />
      <NumberInput source="heightRatioOverride" />
      <BooleanInput source="draft" />
      <BooleanInput source="showOnHomePage" />
      <SelectArrayInput
        label="Tags"
        source="recommendedFor"
        choices={LABELS.map((label) => {
          return { id: label, name: label };
        })}
      />
      <AutocompleteArrayInput
        label="Country allow list"
        source="countryAllowList"
        choices={COUNTRY_CHOICES}
      />
    </SimpleForm>
  </Create>
);

export const UserConceptList = (props: ListProps) => (
  <List {...props} perPage={100} sort={{ field: "priority", order: "DESC" }}>
    <Datagrid rowClick="edit">
      <DateField source="updatedAt" showTime={true} />
      <TextField source="id" />
      <ImageField source="thumbnailPath" />
      <TextField source="type" />
      <NumberField source="version" />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserConceptEdit = (props: EditProps) => {
  return (
    <div>
      <Edit {...props} mutationMode={MUTATION_MODE}>
        <SimpleForm>
          <ImageField source="thumbnailPath" width={240} />
          <TextInput source="id" />
          <TextInput source="name" fullWidth={true} />
          <JSONEditor source="concept" label="concept" />
          <NumberInput source="version" />
          <TextInput source="thumbnailPath" fullWidth={true} />
        </SimpleForm>
      </Edit>
      <AuditEventList {...props} />
    </div>
  );
};

export const CouponList = (props: ListProps) => (
  <List
    {...props}
    perPage={100}
    sort={{ field: "createdAt", order: "DESC" }}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="code" />
      <TextField source="type" />
      <BooleanField source="isTeamCoupon" label="Team?" />
      <BooleanField source="canRedeemMultipleTimes" label="Multiple?" />
      <SelectField source="duration" choices={COUPON_DURATION_CHOICES} />
      <DateField source="createdAt" showTime={true} />
      <DateField source="lastRedeemedAt" showTime={true} />
      <DateField source="expiresAt" showTime={true} />
      <NumberField source="redemptionsCount" label="Redemptions" />
      <NumberField source="redemptionsCount" label="Max redemptions" />
    </Datagrid>
  </List>
);

export const CouponForm = (props: Omit<SimpleFormProps, "children">) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="code" helperText="Case insensitive" />
      <SelectInput source="type" validate={required()} choices={COUPON_TYPES} />
      <BooleanInput
        source="isTeamCoupon"
        validate={required()}
        label="Is team coupon?"
      />
      <DateTimeInput
        source="expiresAt"
        helperText="Optionally set the coupon to become unavailable for redemptions on this date"
      />
      <NumberInput
        source="redemptionsCount"
        helperText="Optionally limit the coupon to this many redemptions"
      />
      <BooleanInput
        source="canRedeemMultipleTimes"
        label="Can redeem multiple times?"
      />
      <SelectInput
        source="duration"
        label="Entitlement duration"
        validate={required()}
        helperText="Duration of entitlement in days"
        choices={COUPON_DURATION_CHOICES}
      />
      <TextInput source="description" helperText="Not shown to users" />
    </SimpleForm>
  );
};

export const CouponCreate = (props: CreateProps) => (
  <Create {...props}>
    <CouponForm />
  </Create>
);

const ToolbarWithoutDelete = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const CouponEdit = (props: EditProps) => (
  <Edit {...props} mutationMode={MUTATION_MODE}>
    <CouponForm toolbar={<ToolbarWithoutDelete />} />
  </Edit>
);

export const MyAppBar = (props: AppBarProps) => {
  const [open, setOpen] = React.useState(false);
  const [appSessionId, setAppSessionId] = React.useState<string>(
    localStorage.getItem("appsessionid") || ""
  );
  const history = useHistory();

  const isListingOfficialTemplates =
    !appSessionId || appSessionId === "" || appSessionId === "default";

  return (
    <AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        id="react-admin-title"
        classes={{ root: "RaAppBar-title-16" }}
      />
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Impersonate an existing user</DialogTitle>
        <DialogContent>
          <MuiTextField
            label="User ID"
            value={appSessionId}
            onChange={(event) => setAppSessionId(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              localStorage.setItem("appsessionid", appSessionId);
              setOpen(false);

              // Changing local storage doesn't trigger a re-render, so we need
              // to reload the page.
              window.location.assign("/");
            }}
          >
            Impersonate
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<PeopleIcon />}
        style={{ color: "white" }}
      >
        {!appSessionId
          ? "Impersonating admin"
          : `Impersonating ${appSessionId}`}
      </Button>
      {isListingOfficialTemplates && (
        <IconButton
          onClick={() => history.push("/import-official-templates")}
          style={{ color: "white" }}
        >
          <PermMediaIcon />
        </IconButton>
      )}
      {isListingOfficialTemplates && (
        <IconButton
          onClick={() => history.push("/preview-homepage")}
          style={{ color: "white" }}
        >
          <HomeIcon />
        </IconButton>
      )}
    </AppBar>
  );
};

export const MyLayout = (props: LayoutProps) => (
  <Layout {...props} appBar={MyAppBar} />
);

const App = () => {
  const appSessionId = localStorage.getItem("appsessionid");
  const isListingOfficialTemplates =
    !appSessionId || appSessionId === "" || appSessionId === "default";

  if (isListingOfficialTemplates) {
    return (
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
        layout={MyLayout}
        customRoutes={[
          <Route
            exact
            path="/import-official-templates"
            component={ImportOfficialTemplates}
          />,
          <Route exact path="/preview-homepage" component={PreviewHomepage} />,
        ]}
      >
        <Resource
          name={"v2/official-templates"}
          list={TemplateList}
          edit={TemplateEdit}
          icon={Brush}
          options={{ label: "Templates" }}
        />
        <Resource
          name="v1/template-category"
          list={TemplateCategoryList}
          edit={TemplateCategoryEdit}
          create={TemplateCategoryCreate}
          icon={Category}
          options={{ label: "Categories" }}
        />
        <Resource
          name="v1/coupons"
          list={CouponList}
          icon={Receipt}
          edit={CouponEdit}
          create={CouponCreate}
          options={{ label: "Coupons" }}
        />
      </Admin>
    );
  } else {
    return (
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
        layout={MyLayout}
      >
        <Resource
          name={"v2/templates"}
          list={TemplateList}
          edit={TemplateEdit}
          icon={Brush}
          options={{ label: "Templates" }}
        />
        <Resource
          name="v2/user-concepts"
          list={UserConceptList}
          edit={UserConceptEdit}
          icon={Layers}
          options={{ label: "User Concepts" }}
        />
      </Admin>
    );
  }
};

export default App;
